<template>
    <v-card>
        <MyCardTitle modulo="Equipe multidisciplinar" :card-info="pessoa"/>
        <v-form ref="form_dadospessoais">
            <v-card-text>
                <v-row dense>
                    <v-col :xl="12" :sm="2" :md="2" :lg="2" :cols="12" class="text-right">
                        <!-- Tela da Foto-->
                        <v-row dense>
                            <v-col cols="12">
                                <v-card class="ma-auto">
                                    <v-card-title>Foto</v-card-title>
                                    <v-card-text class="text-center">
                                        <v-avatar
                                            rounded=""
                                            size="100%"
                                            max-width="100"
                                        >
                                            <v-img 
                                                :loading="loading_avatar" 
                                                :src="pessoa.avatar"
                                            >
                                            </v-img>
                                        </v-avatar>
                                    </v-card-text>
                                    <v-form ref="form_change_avatar">
                                    <v-card-actions class="mx-2">
                                        
                                        <v-file-input counter 
                                            accept="image/png, image/jpeg, image/bmp"
                                            show-size
                                            :label="file_label_avatar"
                                            @change="onFileSelectedAvatar"
                                        >
                                        </v-file-input>
                                    </v-card-actions>
                                    <v-card-actions class="mx-2">
                                        <v-btn 
                                            @click="changeAvatar('avatar')"
                                            outlined
                                            block
                                            v-if="this.img_avatar"
                                            :loading="loading_avatar"
                                        >
                                            {{file_label_avatar}}
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-col>
                        </v-row>
                        <!-- Tela do Carimbo -->
                        <v-row dense>   
                            <v-col cols="12" class="text-center" >
                                <v-card class="ma-auto">
                                    <v-card-title>Carimbo</v-card-title>
                                    <v-card-text>
                                        <v-avatar rounded="" size="100%">
                                            <v-img 
                                                :loading="loading_carimbo"
                                                :src="pessoa.carimbo"></v-img>
                                        </v-avatar>
                                    </v-card-text>
                                    <v-card-actions class="mx-2">
                                        
                                        <v-file-input counter 
                                            accept="image/png, image/jpeg, image/bmp"
                                            show-size
                                            :label="file_label_carimbo"
                                            @change="onFileSelected"
                                        >
                                        </v-file-input>
                                    </v-card-actions>
                                    <v-card-actions class="mx-2">
                                        <v-btn 
                                            @click="changeAvatar('carimbo')"
                                            outlined
                                            block
                                            v-if="this.img_avatar"
                                            :messages="msg_avatar"
                                        >
                                            {{file_label_carimbo}}
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col> 
                        </v-row>
                    </v-col>
                    <v-col :xl="12" :sm="12" :md="10" :lg="10" :cols="12">
                        <!--Dados gerais da equipe-->
                        <v-card class="ma-auto">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="4">
                                        <v-autocomplete
                                            label="Função"
                                            outlined
                                            v-model="pessoa.tipo"
                                            :items="funcoes"
                                            item-value="id"
                                            item-text="nome"
                                            :return-object="true"
                                            @change="update_pessoa('funcao')" 
                                            :messages="msg_funcao"
                                            :error-messages="msg_error_funcao"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="pessoa.cpf"
                                            outlined
                                            label="CPF"
                                            v-mask="'###.###.###-##'"
                                            @change="update_pessoa('cpf')"
                                            :messages="msg_cpf"
                                            :error-messages="msg_error_cpf" 
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="pessoa.rg"
                                            outlined
                                            label="RG"
                                            @change="update_pessoa('rg')"
                                            :messages="msg_rg"
                                            :error-messages="msg_error_rg"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            v-model="pessoa.org_exped"
                                            outlined
                                            :items="orgao_exp"
                                            :return-object="true"
                                            label="Orgão Exp"
                                            item-value="id"
                                            item-text="nome"
                                            @change="update_pessoa('org_exped')"
                                            :messages="msg_org_exped"
                                            :error-messages="msg_error_org_exped"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field 
                                            v-model="pessoa.tel" 
                                            outlined 
                                            label="Telefone" 
                                            @change="update_pessoa('tel')" 
                                            :messages="msg_tel"
                                            :error-messages="msg_error_tel"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            v-model="pessoa.emailequipe" 
                                            outlined label="E-mail" 
                                            @change="update_pessoa('emailequipe')" 
                                            :messages="msg_emailequipe"
                                            :error-messages="msg_error_emailequipe"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            label="Conselho"
                                            outlined
                                            v-model="pessoa.conselho"
                                            :return-object="true" 
                                            :items="conselhos"
                                            item-value="id"
                                            item-text="nome"
                                            @change="update_pessoa('conselho')"
                                            :messages="msg_conselho"
                                            :error-messages="msg_error_conselho"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-autocomplete
                                            v-model="pessoa.uf_inscricao_conselho"
                                            outlined
                                            :return-object="true"
                                            :items="ufs"
                                            item-text="sigla"
                                            item-value="id"
                                            label="UF insc conselho"
                                            @change="update_pessoa('uf_inscricao_conselho')"
                                            :messages="msg_uf_inscricao_conselho"
                                            :error-messages="msg_error_uf_inscricao_conselho"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="pessoa.nconselho"
                                            outlined
                                            label="Nº do Conselho"
                                            @change="update_pessoa('nconselho')"
                                            :messages="msg_cbo"
                                            :error-messages="msg_error_nconselho"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-autocomplete
                                            v-model="pessoa.especialidade"
                                            :return-object="true"
                                            :items="especialidades"
                                            item-value="id"
                                            item-text="nome"
                                            outlined
                                            label="Especialidade"
                                            @change="update_pessoa('especialidade')"
                                            :messages="msg_especialidade"
                                            :error-messages="msg_error_especialidade"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field
                                            :loading="loading_nascimento"
                                            v-model="pessoa.nascimento"
                                            outlined
                                            type="date"
                                            label="Data de Nascimento"
                                            @change="update_pessoa('nascimento')"
                                            :messages="msg_nascimento"
                                            :error-messages="msg_error_nascimento"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <!--Tabela de valores-->
                        <v-card class="mx-auto my-2">
                            <v-row dense>
                                <v-card-title>
                                    Tabela Valores
                                </v-card-title>
                                <v-card-text>

                                    <v-form ref="form_tabela_valor">
                                        <v-row dense>
                                            <v-col v-show="tabela_valor.id" :sm="12" :md="1" :xl="1" :cols="12">
                                                <v-text-field 
                                                    v-model="tabela_valor.id"
                                                    label="ID"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col :sm="12" :md="1" :xl="1" :cols="12">
                                                <v-autocomplete
                                                    v-model="tabela_valor.uf"
                                                    :return-object="true"
                                                    :items="ufs"
                                                    item-value="id"
                                                    item-text="sigla"
                                                    outlined
                                                    label="UF"
                                                    @change="get_localidades(tabela_valor.uf)"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col :sm="12" :md="3" :xl="3" :cols="12">
                                                <v-autocomplete
                                                    :loading="loading_localidades"
                                                    v-model="tabela_valor.localidade"
                                                    :return-object="true"
                                                    :items="localidades"
                                                    item-value="id"
                                                    item-text="localidade"
                                                    outlined
                                                    label="Município"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col :sm="12" :md="2" :xl="2" :cols="12">
                                                <vuetify-money
                                                    v-model="tabela_valor.seg_sex"
                                                    label="Segunda à sexta"
                                                    placeholder="R$ 00,00"
                                                    outlined
                                                    clearable
                                                    valueWhenIsEmpty=""
                                                    :options="options"
                                                />
                                            </v-col>
                                            <v-col :sm="12" :md="2" :xl="2" :cols="12">
                                                <vuetify-money
                                                    v-model="tabela_valor.sab_dom_fer"
                                                    label="Sabado, Domingo e feriados"
                                                    placeholder="R$ 00,00"
                                                    outlined
                                                    clearable
                                                    valueWhenIsEmpty=""
                                                    :options="options"
                                                />
                                            </v-col>
                                            <v-col v-show="tabela_valor.id" :sm="12" :md="1" :xl="1" :cols="12">
                                                <v-checkbox
                                                    v-model="tabela_valor.status"
                                                    label="Ativo"
                                                    :readonly="!tabela_valor.id"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col class="text-center" :sm="12" :md="1" :xl="1" :cols="12">
                                                <v-btn v-if="tabela_valor.id" icon @click="update_tabela_valor()"> 
                                                    <v-icon class="my-auto" size="30">mdi-content-save</v-icon>
                                                </v-btn>
                                                <v-btn v-else icon @click="add_tabela_valor()"> 
                                                    <v-icon class="my-auto" size="40">mdi-plus</v-icon>
                                                </v-btn>
                                                <v-btn v-show="tabela_valor.id" icon @click="limpa_tabela_valor()"> 
                                                    <v-icon class="my-auto" size="30">mdi-cancel</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-row>
                        </v-card>
                        <v-card>
                            <v-data-table 
                                :headers="headers" 
                                :items="tabela_valores"
                                :loading="loading_tabela_valores"
                            >
                                <template v-slot:[`item.ativo`]="{item}">
                                    <v-chip
                                        class="ma-2"
                                        :color="item.status?'blue':'grey'"
                                    >
                                        <span class="white--text">{{item.status?'Ativo':'Inativo'}}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.acoes`]="{item}">
                                    
                                    <v-btn icon @click="edit_tabela_valor(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.seg_sex`]="{item}">
                                    {{ item.seg_sex | formatreal }}
                                </template>
                                <!-- Colocar o formato de valores -->
                                <template v-slot:[`item.sab_dom_fer`]="{item}">
                                    {{ item.seg_sex | formatreal }}
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card class="mx-auto my-2">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-textarea 
                                            outlined label="Observações" 
                                            v-model="pessoa.obs"
                                            @change="update_pessoa('obs')"
                                            :messages="msg_obs"
                                            :error-messages="msg_error_obs"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
        
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import api from '@/http'

export default {
    name: 'DadosPessoais',
    components: { 
        MyCardTitle:() => import("../../../uteis/card_title.vue")
    },

    data:()=>({ 
        img_avatar: null,
        formData: new FormData(),
        avatar_preview: null,
        loading_image: false,
        options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2
        },
        loading_tabela_valores: false,
        loading_nascimento: false,
        loading_avatar: false,
        loading_carimbo: false,

        msg_avatar: '',
        msg_carimbo: '',
        msg_nascimento: '',
        msg_funcao: '', 
        msg_cpf: '', 
        msg_rg: '', 
        msg_org_exped: '', 
        msg_conselho: '', 
        msg_uf_inscricao_conselho: '', 
        msg_cbo: '', 
        msg_especialidade: '', 
        msg_obs: '',

        msg_error_avatar: '',
        msg_error_carimbo: '',
        msg_error_nascimento: '',
        msg_error_funcao: '', 
        msg_error_cpf: '', 
        msg_error_rg: '', 
        msg_error_org_exped: '', 
        msg_error_conselho: '', 
        msg_error_uf_inscricao_conselho: '', 
        msg_error_cbo: '', 
        msg_error_especialidade: '', 
        msg_error_obs: '',

        loading_funcao: false, 
        loading_cpf: false, 
        loading_rg: false, 
        loading_org_exped: false, 
        loading_conselho: false, 
        loading_uf_inscricao_conselho: false, 
        loading_cbo: false, 
        loading_especialidade: false, 
        loading_obs: false,
        loading_localidades: false,

        file_label_avatar: 'Trocar Avatar',
        file_label_carimbo: 'Trocar Carimbo',

        funcoes: [], 
        conselhos: [], 
        especialidades: [], 
        ufs: [],
        uf: '', 
        orgao_exp: [], 

        localidades: [], 
        localidade: '',
        
        tabela_valor: {}, 
        tabela_valores:[],

        headers:[
            {text:'Id', value:'id'},
            {text:'Município', value:'localidade.localidade'},
            {text:'Seg a Sex', value:'seg_sex'},
            {text:'Sab, Dom e feriados', value:'sab_dom_fer'},
            {text:'Status', value:'ativo'},
            {text:'Editar', value:'acoes'},
            ],

    }),
    methods:{
        onFileSelectedAvatar(event){
            this.img_avatar = event
            this.pessoa.avatar = this.img_avatar != null ? window.URL.createObjectURL(event) : null;
        },
        onFileSelected(event){
            this.img_avatar = event
            this.pessoa.carimbo = this.img_avatar != null ? window.URL.createObjectURL(event) : null;
        },
        changeAvatar(val){

            var fd = new FormData()

            if(val=='avatar'){
                fd.append('avatar', this.img_avatar);
            }else{
                fd.append('carimbo', this.img_avatar);
            }
            api.put(`contas/usuario-avatar/${this.pessoa.id}/`,fd,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(()=>{
                if(val=='avatar'){
                    this.msg_avatar = 'avatar atualizado com sucesso'
                    this.msg_error_avatar = ''
                    this.file_label_avatar = 'Atualizado!'
                }else{
                    this.msg_carimbo = 'carimbo atualizado com sucesso'
                    this.msg_error_avatar = ''
                    this.file_label_carimbo = 'Atualizado!'
                }
            }).catch(()=>{
                if(val=='avatar'){
                    this.msg_error_avatar = 'erro ao atualizar'
                    this.file_label_avatar = 'erro ao atualizar Avatar'
                }else{
                    this.msg_error_carimbo = 'erro ao atualizar'
                    this.file_label_carimbo = 'erro ao atualizar Avatar'
                }
            }).finally(()=>{
                this.loading_carimbo = false
                this.loading_avatar = false
                this.msg_avatar = ''
            })
            
        },
        msgs_error(val, msg){
            if(msg.length > 0){
                msg = `${val} ${msg}`
            }
            switch(val){
                case 'nascimento':
                    return this.msg_error_nascimento = `${msg}`
                case 'funcao':
                    return this.msg_error_funcao = `${msg}`
                case 'cpf':
                    return this.msg_error_cpf = `${msg}`
                case 'rg':
                    return this.msg_error_rg = `${msg}`
                case 'org_exped':
                    return this.msg_error_org_exped = `${msg}`
                case 'conselho':
                    return this.msg_error_conselho = `${msg}`
                case 'uf_inscricao_conselho':
                    return this.msg_error_uf_inscricao_conselho = `${msg}`
                case 'cbo':
                    return this.msg_error_cbo = `${msg}`
                case 'especialidade':
                    return this.msg_error_especialidade = `${msg}`
                case 'obs':
                    return this.msg_obs = `${msg}`
            }
        },
        msgs(val, msg){
            if(msg.length > 0){
                msg = `${val} ${msg}`
            }
            switch(val){
                case 'nascimento':
                    return this.msg_nascimento = `${msg}`
                case 'funcao':
                    return this.msg_funcao = `${msg}`
                case 'cpf':
                    return this.msg_cpf = `${msg}`
                case 'rg':
                    return this.msg_rg = `${msg}`
                case 'org_exped':
                    return this.msg_org_exped = `${msg}`
                case 'conselho':
                    return this.msg_conselho = `${msg}`
                case 'uf_inscricao_conselho':
                    return this.msg_uf_inscricao_conselho = `${msg}`
                case 'cbo':
                    return this.msg_cbo = `${msg}`
                case 'especialidade':
                    return this.msg_especialidade = `${msg}`
                case 'obs':
                    return this.msg_obs = `${msg}`
            }
        },
        switch_loading(val){
            if(!val){
                this.loading_nascimento = false
                this.funcao = false
                this.cpf = false
                this.rg = false
                this.org_exped = false
                this.conselho = false
                this.uf_inscricao_conselho = false
                this.cbo = false
                this.especialidade = false
                this.obs = false
            }
            switch(val){
                case 'nascimento':
                    return this.loading_nascimento = true
                case 'funcao':
                    return this.loading_funcao = true
                case 'cpf':
                    return this.loading_cpf = true
                case 'rg':
                    return this.loading_rg = true
                case 'org_exped':
                    return this.loading_org_exped = true
                case 'conselho':
                    return this.loading_conselho = true
                case 'uf_inscricao_conselho':
                    return this.loading_uf_inscricao_conselho = true
                case 'cbo':
                    return this.loading_cbo = true
                case 'especialidade':
                    return this.loading_especialidade = true
                case 'obs':
                    return this.loading_obs = true
            }
        },
        edit_tabela_valor(item){
            this.tabela_valor = item
        },
        async update_tabela_valor(){
            this.loading_tabela_valores = true
            const res = await api.put(`contas/tabela-valor/${this.tabela_valor.id}/`, this.tabela_valor)
            
            if(res.status == 200){
                this.get_tabela_valores()
            }else{
                this.loading_tabela_valores = false
            }
            this.limpa_tabela_valor()
            
        },
        async delete_tabela_valor(item){
            this.loading_tabela_valores = true
            let confirme = confirm('REALMENTE DESEJA EXCLUIR ESTE ITEM?')
            if(confirme){
                const res = await api.delete(`contas/tabela-valor/${item.id}/`)
                if(res.status == 204){
                    this.get_tabela_valores()
                }
            }
            this.limpa_tabela_valor()
            this.loading_tabela_valores = false
        },
        async get_tabela_valores(){
            const res = await api(`contas/tabela-valores/?colaborador=${this.pessoa.id}`)
            if(res.status == 200){
                this.tabela_valores = res.data
                this.loading_tabela_valores = false
            }else{
                this.loading_tabela_valores = false
            }
            this.limpa_tabela_valor()
        },
        limpa_tabela_valor(){
            this.tabela_valor = {}
        },
        async update_pessoa(loading){
            this.switch_loading(loading)
            var obj = Object.fromEntries(Object.entries(this.pessoa)
                .filter(([key]) => !key.includes('avatar'))
                .filter(([key]) => !key.includes('carimbo'))
            )

            if(!this.pessoa.conselho){
                console.log('conselho ',this.pessoa.conselho)
                obj.conselho= {id : 0}
            }

            if(!this.pessoa.especialidade){
                console.log('especialidade ',this.pessoa.especialidade)
                obj.especialidade= {id : 0}
            }           
            if(!this.pessoa.tipo){
                console.log('tipo ',this.pessoa.tipo)
                obj.tipo= {id : 0}
            }            
            if(!this.pessoa.uf_inscricao_conselho){
                console.log('uf_inscricao_conselho ',this.pessoa.uf_inscricao_conselho)
                obj.uf_inscricao_conselho= {id : 0}
            }           
            if(!this.pessoa.org_exped){
                console.log('org_exped ',this.pessoa.org_exped)
                obj.org_exped= {id : 0}
            }            
            console.log(obj)
            const res = await api.put(`contas/usuario/${this.pessoa.id}/`, obj)
            if(res.status == 200){
                this.msgs(loading, 'alterado com sucesso')
                this.msgs_error(loading, '')
            }else{
                this.msgs(loading, '')
                this.msgs_error(loading, 'erro ao alterar')
                // alert(`Erro ao gravar ${loading}`)
            }
            this.switch_loading('')
        },
        async get_funcoes(){
            const res = await api('contas/funcoes/')
            if(res.status == 200){
                this.funcoes = res.data
            }
        },
        async get_conselhos(){
            const res = await api('contas/conselhos/')
            if(res.status == 200){
                this.conselhos = res.data
            }
        },
        async get_especialidades(){
            const res = await api('contas/especialidades/')
            if(res.status == 200){
                this.especialidades = res.data
            }
        },
        get_uf(){
            api('uteis/uf/').then((e)=>{
                this.ufs = e.data
            })
        },
        async get_orgao_exp(){
            const res = await api('uteis/orgao_exp/')
            if(res.status == 200){
                this.orgao_exp = res.data
            }
        },
        get_localidades(obj=null){
            this.loading_localidades = true
            console.log(obj)
            api(`uteis/localidades/?uf=${obj.sigla}`).then((e)=>{
                this.localidades = e.data
            }).finally(()=>{    this.loading_localidades = false; })
        },
        async add_tabela_valor(){
            let colaborador = this.pessoa.id
            let localidade = this.tabela_valor.localidade.id
            const {seg_sex, sab_dom_fer} = this.tabela_valor
            let obj = {  localidade, colaborador, seg_sex, sab_dom_fer }
            
            const res = await api.post(`contas/tabela-valores/`, obj)

            if(res.status == 201) { 
                res
                this.get_tabela_valores()
            }else{
                console.log(res)
            }
            
        }
    },
    computed:{
        ...mapGetters(['pessoa'])
        ,computed_avatar_preview(){
            if (this.pessoa!=undefined){
                let usu = this.pessoa
                if (this.img_avatar != undefined){
                    return this.img_avatar_preview
                }else if ((usu.avatar || '').length > 0){
                    return usu.avatar
                }else{
                    return 'https://f1.pngfuel.com/png/933/945/137/social-media-icons-background-avatar-user-profile-login-black-circle-silhouette-symbol-png-clip-art.png'
                }
            }else{
                return 'https://f1.pngfuel.com/png/933/945/137/social-media-icons-background-avatar-user-profile-login-black-circle-silhouette-symbol-png-clip-art.png'
            }
        },
    },
    mounted(){
        this.get_funcoes()
        this.get_conselhos()
        this.get_especialidades()
        this.get_uf()
        this.get_orgao_exp()
        this.get_tabela_valores()   
    }
}
</script>